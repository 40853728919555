import { TFunction } from 'i18next'
import { useEffect, useState } from 'react'
import { TestSubjectKey } from '~/types/api/alpha/test-key'
import LineTestChart from '../graph/LineTestChart'
import { TestResultByGradeRow } from './past-result/types/table'

type Props = {
  isUsingJp: boolean

  testKeys: TestSubjectKey[]
  currentTests: Record<
    string,
    { title: string | JSX.Element; suffix: string | JSX.Element } | undefined
  >
  t: TFunction<'translation', undefined>
  dataTest: TestResultByGradeRow
}

const PastChart = ({
  isUsingJp,
  testKeys,
  currentTests,
  t,
  dataTest,
}: Props) => {
  const [dataChart, setDataChart] = useState<
    {
      suffix: string | JSX.Element
      name: string
      value: number | null
    }[]
  >([])

  /**
   * Test subject keyword
   */
  const [chart, setChart] = useState<TestSubjectKey>('grip')

  useEffect(() => {
    const testResult = dataTest[chart]

    if (!testResult?.length) {
      return
    }

    const isReverseChart = chart === 'sprintRun' || chart === 'enduranceRun'
    const dataChartTest: {
      suffix: string | JSX.Element
      name: string
      value: number | null
    }[] = []

    testResult.forEach((value, index) => {
      dataChartTest.push({
        suffix: (currentTests[chart] as { suffix: string }).suffix,
        name: t(dataTest.title[index]),
        value: isReverseChart && typeof value === 'number' ? 0 - value : value,
      })
    })

    setDataChart(dataChartTest)
  }, [dataTest, chart, t])

  return (
    <>
      <div className="show-pc">
        <div className="grid grid-cols-8 list-exam-graph">
          {testKeys.map((key) => {
            if (['weight', 'height'].includes(key)) {
              return
            }

            return (
              <div
                key={key}
                className={`${chart === key ? 'exam-active ' : ''}text-center${
                  isUsingJp ? '' : '  text-12px'
                }`}
                onClick={() => setChart(key)}
                style={
                  key === 'shuttleRun'
                    ? {
                        fontSize: 11,
                      }
                    : {}
                }
              >
                {currentTests[key]!.title}
              </div>
            )
          })}
        </div>
      </div>

      <div className="show-sp">
        <div className="grid grid-cols-4 list-exam-graph">
          {testKeys.map((key) => {
            if (['weight', 'height'].includes(key)) {
              return
            }

            return (
              <div
                key={key}
                className={`${chart === key ? 'exam-active' : ''}text-center`}
                onClick={() => setChart(key)}
                style={
                  key === 'shuttleRun'
                    ? {
                        fontSize: 11,
                      }
                    : {}
                }
              >
                {currentTests[key]!.title}
              </div>
            )
          })}
        </div>
      </div>
      <div className="mt-5">
        <LineTestChart dataChart={dataChart} />
      </div>
    </>
  )
}

export default PastChart
